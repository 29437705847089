<template>
  <ion-page class="welcome-page">
    <ion-slides
      pager="true"
      :options="slideOpts"
      ref="slide"
      class="welcome-slide"
      v-if="showSlider"
    >
      <ion-slide v-for="(slide, key) in slides" :key="key">
        <img class="image" :src="slide.image" />
        <!-- <ion-img class="image" :src="slide.image"></ion-img> -->
        <h2 class="title">{{ slide.title }}</h2>
        <p class="description">{{ slide.description }}</p>
      </ion-slide>
    </ion-slides>

    <section class="start-now ion-padding">
      <ion-button
        expand="block"
        class="btn-default"
        @click.prevent="router.push({ path: '/auth-selector' })"
      >
        {{ $t("Get started") }}
      </ion-button>
    </section>
  </ion-page>
</template>

<script>
import { IonPage, IonSlides, IonSlide, IonButton } from "@ionic/vue";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default {
  name: "Welcome",
  components: {
    IonPage,
    IonSlides,
    IonSlide,
    IonButton
  },
  setup() {
    const slide = ref(null);
    const { t } = useI18n();
    const router = useRouter();
    const showSlider = ref(false);

    const slides = [
      {
        title: t("Seniors, families and professionals - easily connected!"),
        description: t(
          "A simple way to get organised. Day-to-day tracking, calendar, reminders for medicines, medical appointments, activities, tests..."
        ),
        image: "/assets/slides/slide-1.svg"
      },
      {
        title: t("Your digital home care assistant"),
        description: t(
          "Everything you need to stay at home safely, comfortably, carefree and with the best care, in a single platform."
        ),
        image: "/assets/slides/slide-2.svg"
      }
    ];

    onMounted(() => {
      setTimeout(() => (showSlider.value = true), 300);
      setTimeout(
        () => document.querySelector(".welcome-page").classList.add("visible"),
        310
      );
    });

    const slideOpts = {
      initialSlide: 0,
      speed: 400
    };
    return {
      slideOpts,
      slide,
      slides,
      t,
      router,
      showSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";
$max-width: 250px;

.welcome-page {
  opacity: 0;
  transition: opacity 1s ease-out;
  width: 100%;
  height: 100%;
  &.visible {
    opacity: 1;
  }
}

ion-slides {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 100%;

  ion-slide {
    width: 100%;
    max-width: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 2.8rem;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $color-black;
      max-width: $max-width;
    }

    .image {
      width: 100%;
      max-width: 190px;
      margin-bottom: 20px;
      pointer-events: none;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $color-grey;
    max-width: $max-width;

    b,
    strong {
      font-weight: 500;
      color: $color-black;
    }
  }
}

.start-now {
  height: 15%;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $color-grey;
    text-align: center;

    a {
      margin: 0 5px;
    }
  }
}
</style>

<style lang="scss">
// Lo tengo que poner así porque sino no lo coge correctamente .
// El scope no funciona correctamente
@import "@/theme/custom-variables";

.welcome-slide {
  > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
  }

  .swiper-wrapper {
    height: unset;
  }

  .swiper-pagination {
    margin-top: 10px;
    position: unset;
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 4px;
    display: inline-block;
    border-radius: 0;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
</style>
